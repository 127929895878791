import css from 'styled-jsx/css';

export default css`
  .top-banner {
    position: absolute !important;
    z-index: 8;
    box-sizing: border-box;
    align-items: center;
    background: #e1fe00;
    top: 55px;
    border-bottom: var(--line-header);
  }
  .top-banner-min,
  .top-banner-max {
    left: 0px;
    width: 100%;
  }
  .margin-content {
    margin-top: 45px;
  }
  .main {
    padding: 12px;
    flex-grow: 1;
    display: flex;
  }
  @media only screen and (min-width: 992px) {
    .top-banner {
      position: fixed;
      z-index: 8;
      box-sizing: border-box;
      align-items: center;
      background: #dc3a3a;
      top: 55px;
      border-bottom: var(--line-header);
    }
    .top-banner {
      top: 65px;
    }
    .top-banner-min {
      left: 88px;
      width: calc(100% - 88px);
    }
    .top-banner-max {
      left: 250px;
      width: calc(100% - 250px);
    }
    .margin-content {
      margin-top: 50px;
    }
    .top-banner-message-box p {
      color: black;
      font-size: 13px;
      font-weight: 400;
      display: flex;
      gap: 5px;
      align-items: center;
    }
    .top-banner-message-box {
      padding: 0.7em;
      width: 100%;
    }
  }
  .top-banner-message-box {
    padding: 0.3em;
    width: 100%;
  }
  .top-banner-message-box p {
    color: black;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    gap: 5px;
    align-items: center;
  }
  :global(body) {
    background-color: var(--background);
  }
  .overlay-body {
    overflow: hidden;
  }

  .content {
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
  }
  @media only screen and (min-width: 992px) {
    .main {
      padding: 16px 8px;
      flex-grow: 1;
      display: flex;
    }
    .content {
      margin-left: auto;
      margin-right: auto;
      flex-wrap: wrap;
      width: 100%;
      box-sizing: border-box;
    }
    @media only screen and (min-width: 768px) {
      .main {
        padding: 16px 32px;
      }
    }
    @media only screen and (min-width: 28em) {
      .content {
        max-width: 1440px;
      }
    }
    .no-padding {
      padding: 0 !important;
    }
  }
`;
