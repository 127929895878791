import useTranslation from 'hooks/useTranslation';
import { useCallback, useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { hotjar } from 'react-hotjar';
import { useDispatch, useSelector } from 'react-redux';
import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';
import { useIsEmbedded } from '../../hooks/useIsEmbedded';
import useResponsive from '../../hooks/useResponsive';
import SidebarFree from './SidebarFree';
import HeaderFree from './HeaderFree';
import {
  setChannelcode,
  setOrdersListFilters,
  setRedirectUrl,
} from 'actions/ui';
import { useRouterWithLang } from 'hooks/useRouterWithLang';
import PopupNewSignup from '../../components/generic/PopupNewSignup';
import dashboardStyle from './dashboard.style';
import embeddedDashboardStyle from './embedded-dashboard.style';

function Dashboard({ children, isIframe }) {
  const { TRANSLATIONS, translationsParameters, locale } = useTranslation();
  const isEmbedded = useIsEmbedded();
  const { isMobile } = useResponsive();
  const [menuOpen, setMenuOpen] = useState(false);
  const router = useRouterWithLang();
  const userIsLoading = useSelector((state) => state.userIsLoading);
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const PERMISSIONS = useSelector((state) => state.permissions);
  const user = useSelector((state) => state.user);
  const userFree = PERMISSIONS.IS_FREE_USER || PERMISSIONS.IS_ONLY_SOCIAL;
  const menuOpenHandler = useCallback(() => {
    setMenuOpen((prevState) => !prevState);
  }, []);
  const [inactive, setInactive] = useState(false);
  const redirectUrl = useSelector((state) => state.UI.redirectUrl);
  const dispatch = useDispatch();
  const handleInactive = useCallback(() => {
    setInactive(!inactive);
  }, [inactive]);
  const channelcode = useSelector((state) => state.UI.channelcode);
  const showNewSignupPopup = useSelector((s) => s.showNewSignupPopup);
  const { lang } = router.query;
  const prevPathRef = useRef(router.asPath);

  useEffect(() => {
    if (menuOpen || inactive) {
      document.body.classList.add('overlay-body');
    } else document.body.classList.remove('overlay-body');
  }, [inactive, menuOpen]);

  useEffect(() => {
    if (window.location.origin === process.env.DASHBOARD_URL) {
      hotjar.initialize(2013607, 6);
      hotjar.identify(user._id, { email: user.email, language: locale });
    }
  }, [locale, user._id, user.email]);

  useEffect(() => {
    if (redirectUrl) {
      dispatch(setRedirectUrl({ redirectUrl: '' }));
    }
  }, [dispatch, redirectUrl]);

  useEffect(() => {
    if (channelcode) {
      dispatch(setChannelcode({ channelcode: '' }));
    }
  }, [channelcode, dispatch, redirectUrl]);

  const conditionMessageFree =
    TRANSLATIONS.extraField.message !== null &&
    !isEmbedded &&
    userFree &&
    user?.roles?.[0]?.name !== 'shopify-trial';

  const conditionMessage =
    TRANSLATIONS.extraField.message !== null &&
    !isEmbedded &&
    router.pathname === '/' &&
    !userFree &&
    user?.roles?.[0]?.name !== 'shopify-trial';

  // Resetta i filtri della pagina degli ordini quando si abbandona la pagina
  useEffect(() => {
    const handleRouteChange = (url) => {
      const prevPath = prevPathRef.current;
      const currentPath = url;
      prevPathRef.current = currentPath;
      const isLeavingOrderRoute =
        prevPath.startsWith('/order') && !currentPath.startsWith('/order');

      if (isLeavingOrderRoute) {
        dispatch(setOrdersListFilters({}));
      }
    };
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events, dispatch]);

  if (userIsLoading) {
    return null;
  }

  if (!isLoggedIn && typeof window !== 'undefined') {
    return !!router.push('/');
  }

  if (router.pathname.startsWith('/admin') && !PERMISSIONS.IS_ADMIN) {
    return !!router.push('/');
  }

  if (isEmbedded) {
    return (
      <>
        <main className="main" onClick={() => menuOpen && menuOpenHandler()}>
          <div className={`content`}>{children}</div>
        </main>
        <style jsx>{embeddedDashboardStyle}</style>
      </>
    );
  }

  return (
    <div className={`layout-dashboard`}>
      {conditionMessageFree && (
        <div className={`top-banner ${!userFree && 'top-banner__fixed'}`}>
          <div className={'top-banner-message-box d-f fd-c jc-c ai-c'}>
            <p className={'ta-c'}>
              {translationsParameters(TRANSLATIONS.extraField.message, [
                // eslint-disable-next-line react/jsx-key
                <a href={TRANSLATIONS.extraField.href} rel="noreferrer">
                  {TRANSLATIONS.extraField.cta}
                </a>,
              ])}
            </p>
          </div>
        </div>
      )}
      <div className={`${!isMobile && !userFree && 'grid-container'}`}>
        {userFree && (
          <>
            <HeaderFree menuOpenHandler={menuOpenHandler} />
            <SidebarFree
              menuOpen={menuOpen}
              menuOpenHandler={menuOpenHandler}
            />
          </>
        )}
        {!userFree && (
          <>
            <Header inactive={inactive} handleInactive={handleInactive} />
            {!userFree && (
              <Sidebar
                inactive={inactive}
                handleInactive={handleInactive}
                userFree={userFree}
              />
            )}
          </>
        )}
        <main
          className={`main ${isIframe ? 'no-padding' : ''} ${
            userFree && 'm-h'
          }`}
        >
          <div className={`content`}>
            {conditionMessage && (
              <div className={`top-banner relative`}>
                <div className={'top-banner-message-box d-f fd-c jc-c ai-c'}>
                  <p className={'ta-c'}>
                    {translationsParameters(TRANSLATIONS.extraField.message, [
                      // eslint-disable-next-line react/jsx-key
                      <a href={TRANSLATIONS.extraField.href} rel="noreferrer">
                        {TRANSLATIONS.extraField.cta}
                      </a>,
                    ])}
                  </p>
                </div>
              </div>
            )}
            {showNewSignupPopup && lang === 'it_IT' && <PopupNewSignup />}
            {children}
          </div>
        </main>
        <Footer />
      </div>
      <style jsx>{dashboardStyle}</style>
    </div>
  );
}

export default Dashboard;
