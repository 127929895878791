import css from 'styled-jsx/css';

export default css`
        .top-banner {
          z-index: 2;
          box-sizing: border-box;
          align-items: center;
          padding: 4px;
          background: var(--banner-top);
          grid-area: banner;
        }
        .top-banner-message-box p {
          color: var(--top-banner-p);
          font-size: 13px;
          font-weight: 500;
        }
        :global(body) {
          background-color: var(--background);
        }
        .overlay-body {
          overflow: hidden;
        }
        .content {
          margin-left: auto;
          margin-right: auto;
          flex-wrap: wrap;
          width: 100%;
          box-sizing: border-box;
        }
        .grid-container {
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: 105px 1fr 600px;
          grid-template-areas:
            'header'
            'main'
            'footer';
          height: 100vh;
          background: var(--background);
        }

        @media only screen and (min-width: 768px) {
          .main {
            padding: 16px 32px;
            grid-area: main;
          }
          .grid-container {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 94px 1fr 500px;
            grid-template-areas:
              ' header'
              ' main'
              ' footer';
            height: 100vh;
            background: var(--background);
          }
        }

        @media only screen and (min-width: 992px) {
          .main {
            grid-area: main;
            padding: 16px 8px;
            flex-grow: 1;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
          }
          .content {
            margin-left: auto;
            margin-right: auto;
            flex-wrap: wrap;
            width: 100%;
            box-sizing: border-box;
          }
          .grid-container {
            display: grid;
            grid-template-columns: 250px 1fr;
            grid-template-rows: 107px 1fr 600px;
            grid-template-areas:
              'sidenav header'
              'sidenav main'
              'sidenav footer';
            height: 100vh;
            background: var(--background);
          }
          .grid-container-top-banner {
            display: grid;
            grid-template-columns: 250px 1fr;
            grid-template-rows: 40px 105px 1fr 600px;
            grid-template-areas:
              'banner'
              'sidenav header'
              'sidenav main'
              'sidenav footer';
            height: 100vh;
            background: var(--background);
          }
          .top-banner {
            z-index: 2;
            box-sizing: border-box;
            align-items: center;
            padding: 0px;
            background: var(--banner-top);
          }
          .top-banner {
            width: 100%;
          }
          .top-banner-message-box p {
            color: var(--top-banner-p);
            font-size: 13px;
            font-weight: 300;
            letter-spacing: 0.5px;
          }
          .top-banner-message-box a {
            color: var(--top-banner-p);
            font-size: 13px;
            font-weight: 700;
            text-decoration: underline;
          }
          .top-banner-message-box {
            padding: 0.4em;
            width: 100%;
          }
        }
        @media only screen and (min-width: 28em) {
          .content {
            max-width: 1440px;
          }
        }
        .main {
          padding: 16px;
          -webkit-box-flex: 1;
          -webkit-flex-grow: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          grid-area: main;
        }
        .m-h {
          min-height: 50vh;
        }
        .no-padding {
          padding: 0 !important;
        }
      `;
